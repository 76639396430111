/**
    sheet:{cover: "r/mindyushu.oss-cn-shanghai.aliyuncs.com/mindmap/cover/e12e3f1435161df339be20f793ef1cb2.png"
    id: "62d4d7c6103c41b62702d299"
    index: 0
    name: "分支主题 14"
    rootId: "62d4d7c6103c41b62702d299"} 
    */
class SheetData {
    constructor() {
        this.name = "";
        this.cover = "";
        this.id = "";
        this.rootId = "";
        this.index = 0;
        this.lastchangetime = 0;
        this.fileName = "";
        this.type = 1;
    }
}
export default SheetData