import { md5 } from "../../../../common/netWork/base";
import MindMe from "../../../facade/MindMe";

class MindContentData {
    constructor() {
        this.name = "";
        this.fileName = "";
        this.id = "";
        this.mindType = 1;
        this.content = null;
        this.lastchangetime = -1;
    }

    setFileName(fileName, isMd5 = true) {
        if (isMd5) {
            this.fileName = md5(fileName) + ".json";   
        } else {
            this.fileName = fileName;   
        }
    }

    setName(name) {
        this.name = name;
    }

    getContentJson() {        
        let json = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return json;
    }
}
export default MindContentData