/*
{totalNum: 4, pageSize: 20, sheets: Array(4)}
*/

class MindSheets {
    constructor() {
        this.totalNum = 1;
        this.pageSize = 20;
        this.sheets = [];
    }

    toJSONString() {
        let nodeStr = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return nodeStr;
    }
}
export default MindSheets