import { getMachineId } from "../../../../common/netWork/base";
import Util from "../../../../utils/Util";
import MindMe from "../../../facade/MindMe";
import FilePathAndName from "./data/FilePathAndName";
import MindContentData from "./MindContentData";
import MindContentJsonData from "./MindContentJsonData";

class MapSaveData {
    constructor() {
        this.path = "";//存储路径
        this.name = "";
        this.contentList = [];
        this.images = []; //cell ImageFileData
        this.files = []; //cell FileData
        this.version = -1.0
        this.id = "";
        this.mid = "";
    }

    getContentListJson(isSaveAs = false) {
        if (this.contentList == null || this.contentList.length == 0) {
            return "{}";
        }
        let data = new MindContentJsonData()
        let list = [];
        for (let index = 0; index < this.contentList.length; index++) {
            const content = this.contentList[index];
            let cell = new MindContentData();
            cell.content = "";
            if (isSaveAs) {
                cell.id = "" + index;
            } else {
                cell.id = content.id;
            }
            
            cell.name = content.name;
            cell.fileName = content.fileName
            cell.mindType = content.mindType
            list.push(cell);
        }
        data.version = this.version;
        if (MindMe.isLogin()) {
            data.id = MindMe.getUserId()
        } else {
            data.id = "";
        }
        data.mid = getMachineId()
        data.lastsavetime = Util.getCurrentTime()
        data.data = list;
        for (let index = 0; index < this.files.length; index++) {
            let cell = this.files[index]
            let fileData = new FilePathAndName()
            fileData.name = cell.name;
            fileData.path = cell.path;
            data.files.push(fileData)
        }
        let json = JSON.stringify(data, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return json;
    }

    format(json) {
        let mapSaveData = new MapSaveData()
        let data = JSON.parse(json)
        for (var key in data) {
            let keyValue = data[key]
            if (key == "data") {
                var list = []
                 for (let index = 0; index < keyValue.length; index++) {
                    let jsonDataCell = keyValue[index]
                    let cell = new MindContentData();
                    cell.content = "";
                    cell.id = jsonDataCell.id;
                    cell.name = jsonDataCell.name;
                    cell.fileName = jsonDataCell.fileName
                    cell.mindType = jsonDataCell.mindType
                    list.push(cell);
                 }
                 mapSaveData.contentList = list
            } else {
                mapSaveData[key] = keyValue
            }
        }
        return mapSaveData
    }
}
export default MapSaveData